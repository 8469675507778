import Home from './Home'
import Navbar from "./Navbar";

function HomePage(props) {

    return (
        <div>
            <Home />
        </div>
    );
}
export default HomePage;