import Navbar from "../Navbar";
import Games from "./Games";

function GamesPage() {

    return (
        <div className="relative">
            <Games />
        </div>
    );
}
export default GamesPage;